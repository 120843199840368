/**
 * Cookie
 * Contains methods to simplify cookie management.
 * @see {@link http://www.w3schools.com/js/js_cookies.asp}
 * @version 3.0.0
 */

/**
 * Sets a cookie.
 * @param {String} cname - The name of the cookie.
 * @param {String} cvalue - The value of the cookie.
 * @param {Number|Null} exdays - The number of days until the cookie should expire (can be null).
 * @param {String|Null} path - The path to set the cookie to (can be null).
 * @public
 */
export const setCookie = (cname, cvalue, exdays, path) => {
	let d = new Date(),
		cookieContent = cname + '=' + cvalue,
		expires;

	if (exdays !== null && exdays !== undefined) {
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = '; expires=' + d.toUTCString();
		cookieContent += expires;
	}

	if (path !== null && path !== undefined) {
		cookieContent += '; path=' + path;
	}
	document.cookie = cookieContent;
};

/**
 * Gets a cookie.
 * @param {String} cname - The name of the cookie.
 * @public
 */
export const getCookie = cname => {
	let name = cname + '=',
		ca = document.cookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
};

/* eslint one-var: "off" */
import axios from 'axios';
import { remove } from '../utilities/utilities';
import Choices from 'choices.js';

/**
 * EpiserverForm
 * A plugin that handles Episerver forms in an accessible way.
 * @version 1.0.0
 * @exports episerverForm
 */
export default class EpiserverForm {
	/**
	 * Constructor
	 * @param {Object} el - A DOM node.
	 * @public
	 */
	constructor(el) {
		/** Placeholder for form node. */
		this.formNode = el;

		/** Boolean to indicate if there are any ajax-request in progress. */
		this.requestInProgress = false;

		this._init();
	}

	/**
	 * The initialization function for this module.
	 * @public
	 */
	_init() {
		this.formMessageNode = this.formNode.querySelector('.form__message');
		this.fieldHolderNode = this.formNode.querySelector('.form__fields-holder');

		this._initialFocus();
		this._initMultipleSelects();
		this._initEvents();
	}

	/**
	 * Function that sets focus to the form__status container on load.
	 * @private
	 */
	_initialFocus() {
		const statusNode = this.formNode.querySelector('.form__status');

		if (statusNode) {
			statusNode.focus();
		}
	}

	/**
	 * Initiates multiple selects with Choices.js
	 * @private
	 */
	_initMultipleSelects() {
		var multipleSelects = this.formNode.querySelectorAll('select[multiple]');

		multipleSelects.forEach(select => {
			new Choices(select, {
				removeItemButton: true,
				placeholder: true,
				placeholderValue: select.getAttribute('data-placeholder') || '',
				itemSelectText: 'Klicka för att välja',
				noChoicesText: 'Det finns inga alternativ att välja på.',
				noResultsText: 'Inga resultat.',
				loadingText: 'Laddar...',
			});

			select.addEventListener(
				'removeItem',
				() => {
					this._cleanupFieldValidationError(select);
					this._checkIfFieldHasValidationErrors(select);
				},
				false
			);

			select.addEventListener(
				'addItem',
				() => {
					this._cleanupFieldValidationError(select);
					this._checkIfFieldHasValidationErrors(select);
				},
				false
			);
		});
	}

	/**
	 * Function that submits the form through ajax.
	 * @private
	 */
	_submit(stepData, stepId) {
		const method = this.formNode.getAttribute('method')
			? this.formNode.getAttribute('method')
			: 'POST';
		const url = this.formNode.getAttribute('action');
		const formData = stepData || new FormData(this.formNode);
		let data = {};

		if (this.requestInProgress === false) {
			this.requestInProgress = true;

			axios({
				url: url,
				method: method,
				data: formData,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then(response => {
					if (response.status === 200) {
						data = response.data;

						if (data.RedirectUrl) {
							window.location.href = data.RedirectUrl;
							return;
						}

						this._cleanupValidationErrors();
						this._hideLoadingIndicator();

						if (!data.IsSuccess) {
							this._handleFormError(data);
						}

						// Update hidden fields if needed.
						if (stepId && data.Data && data.Data.CurrentStepIndex) {
							for (let key in data.Data) {
								if (data.Data.hasOwnProperty(key)) {
									const field = this.formNode.querySelector(
										'[name="__Form' + key + '"]'
									);
									if (field) {
										field.value = data.Data[key];
									}
								}
							}
						}
						if (stepId && data.IsSuccess) {
							this._showStep(stepId);
						} else if (
							this.formMessageNode &&
							data.Message &&
							data.Message !== ''
						) {
							this._displayMessage(data);
						}
					} else {
						this._hideLoadingIndicator();
						alert(
							this.formNode.getAttribute('data-server-error-message') ||
								'Ett fel inträffade, vänligen försök igen senare.'
						);
					}
					this.requestInProgress = false;
				})
				.catch(() => {});
		}
	}

	/**
	 * Removes validation invalid classes and validation error labels.
	 * @private
	 */
	_cleanupValidationErrors() {
		const invalidFields = this.formNode.querySelectorAll(
			'.form__field--invalid'
		);
		const invalidLabels = this.formNode.querySelectorAll(
			'.form__label--invalid'
		);

		invalidFields.forEach(field => {
			field.classList.remove('form__field--invalid');
		});

		invalidLabels.forEach(label => {
			remove(label);
		});
	}

	/**
	 * Cleans up validation error for a specific field
	 * @param {Node} field - The field node
	 * @private
	 */
	_cleanupFieldValidationError(field) {
		const invalidLabel = this._getFieldLabelNode(field).querySelector(
			'.form__label--invalid'
		);

		const formField =
			field.tagName === 'SELECT' && field.getAttribute('multiple')
				? field.parentNode.querySelector('input')
				: field;

		formField.classList.remove('form__field--invalid');

		if (invalidLabel) {
			remove(invalidLabel);
		}
	}

	/**
	 * Returns the label node for the provided field
	 * @param {Node} field - The field node
	 * @private
	 */
	_getFieldLabelNode(field) {
		if (field.classList.contains('choices__input')) {
			return field.parentNode.parentNode.parentNode.querySelector('label');
		} else if (field.tagName === 'FIELDSET') {
			return field.querySelector('legend');
		} else {
			return field.parentNode.querySelector('label');
		}
	}

	/**
	 * Handles serverside validation errors.
	 * @param {Object} data - Form response data.
	 * @private
	 */
	_handleFormError(data) {
		if (data.AdditionalParams && data.AdditionalParams.__FormField) {
			let displayMessageHTML = '<ul>';

			data.AdditionalParams.__FormField.forEach((field, index) => {
				const fieldNode = document.getElementById(field.InvalidElement);
				let fieldType;

				displayMessageHTML +=
					'<li><strong>' +
					field.InvalidElementLabel +
					'</strong>' +
					'<span class="form__label--invalid">' +
					field.ValidationMessage +
					'</span></li>';

				if (fieldNode) {
					fieldType = fieldNode.getAttribute('type');
					fieldNode.classList.add('form__field--invalid');

					if (field.ValidationMessage && field.ValidationMessage !== '') {
						const validationMessageNode = document.createElement('span');
						const validationMessage = document.createTextNode(
							field.ValidationMessage
						);

						validationMessageNode.appendChild(validationMessage);
						validationMessageNode.classList.add('form__label--invalid');

						if (fieldNode.tagName === 'FIELDSET') {
							validationMessageNode.setAttribute('tabindex', -1);

							const fieldNodeLegend = fieldNode.querySelector('legend');
							if (fieldNodeLegend) {
								fieldNodeLegend.appendChild(validationMessageNode);
							}

							if (index === 0) {
								validationMessageNode.focus();
							}
						} else {
							const fieldNodeLabel = this.formNode.querySelector(
								'[for="' + field.InvalidElement + '"]'
							);

							if (fieldType === 'checkbox' || fieldType === 'radio') {
								if (fieldNodeLabel) {
									fieldNodeLabel.appendChild(validationMessageNode);
								}
							} else {
								fieldNodeLabel.appendChild(validationMessageNode);
							}
						}
					}

					if (index === 0) {
						if (fieldNode.tagName !== 'FIELDSET') {
							fieldNode.focus();
						}

						// Set cursor at the end of the fields value.
						if (fieldNode.selectionStart || fieldNode.selectionStart == '0') {
							const elementValueLength = fieldNode.value.length;
							fieldNode.selectionStart = elementValueLength;
							fieldNode.selectionEnd = elementValueLength;
							fieldNode.focus();
						}
					}
				}
			});

			displayMessageHTML += '</ul>';
			this._displayValidationErrorSummary(
				data.AdditionalParams.__FormField.length,
				displayMessageHTML
			);
		}
	}

	/**
	 * Displays a validation error summary above the form.
	 * @param {Number} nrOfErrors - Number of fields that has validation errors.
	 * @param {String} fieldsListHTML - String of HTML containing a ul-list with invalid field labels.
	 * @private
	 */
	_displayValidationErrorSummary(nrOfErrors, fieldsListHTML) {
		this._cleanupOldStatusMessages();

		let errorMessage =
				this.formNode.getAttribute('data-validation-error-message') ||
				'Formuläret innehåller {0} fel:',
			headlineNode = document.createElement('h2'),
			responseMessageNode = this._createMessageNode();

		headlineNode.innerHTML = errorMessage.replace('{0}', nrOfErrors);
		responseMessageNode.appendChild(headlineNode);
		responseMessageNode.innerHTML += fieldsListHTML;

		this.formMessageNode.appendChild(responseMessageNode);
		responseMessageNode.focus();
	}

	/**
	 * Cleans up old status messages
	 * @private
	 */
	_cleanupOldStatusMessages() {
		const statuses = this.formMessageNode.querySelectorAll('.form__status');

		statuses.forEach(status => {
			remove(status);
		});
	}

	/**
	 * Creates a div message node and sets correct classes.
	 * @param {Object} data - Form response data.
	 * @private
	 */
	_createMessageNode(data) {
		const responseMessageNode = document.createElement('div');

		responseMessageNode.setAttribute('tabindex', '-1');
		responseMessageNode.classList.add('form__status');
		responseMessageNode.classList.add('form__rte');

		if (data && data.IsSuccess) {
			responseMessageNode.classList.add('form__status--success');
		} else {
			responseMessageNode.classList.add('form__status--error');
		}

		return responseMessageNode;
	}

	/**
	 * Displays a message above the form.
	 * @param {Object} data - Form response data.
	 * @private
	 */
	_displayMessage(data) {
		const responseMessageNode = this._createMessageNode(data);

		responseMessageNode.innerHTML = data.Message;

		// Remove all content in formMessageNode
		while (this.formMessageNode.firstChild) {
			this.formMessageNode.removeChild(this.formMessageNode.firstChild);
		}

		this.formMessageNode.appendChild(responseMessageNode);
		responseMessageNode.focus();
		this.fieldHolderNode.classList.add('form__fields-holder--hidden');
	}

	/**
	 * Submits a step in a multi step form.
	 * @param {String} stepId - The id of the step section node.
	 * @private
	 */
	_submitStep(stepId) {
		const currentStep = this.formNode.querySelector(
			'.form__section[aria-hidden="false"]'
		);
		let formData = new FormData();
		let currentStepFields = [];

		formData = this._getHiddenFieldValues(formData);

		if (currentStep) {
			currentStepFields = currentStep.querySelectorAll(
				'input, select, textarea'
			);

			currentStepFields.forEach(field => {
				if (!field.classList.contains('choices__input--cloned')) {
					formData = this._getFieldValue(field, formData);
				}
			});

			this._submit(formData, stepId);
		}
	}

	/**
	 * Extracts values from hidden fields and appends to FormData object
	 * @param {FormData} formData - Current form data
	 * @returns {FormData}
	 * @private
	 */
	_getHiddenFieldValues(formData) {
		const hiddenFieldHolder = this.formNode.querySelector(
			'.form__hidden-fields'
		);
		let hiddenFields = [];

		if (hiddenFieldHolder) {
			hiddenFields = hiddenFieldHolder.querySelectorAll('input, select');

			hiddenFields.forEach(field => {
				formData.append(field.getAttribute('name'), field.value);
			});
		}

		return formData;
	}

	/**
	 * Extracts value from the given field and appends to FormData object
	 * @param {Node} field - The current field node
	 * @param {FormData} formData - Current form data
	 * @returns {FormData}
	 * @private
	 */
	_getFieldValue(field, formData) {
		const type = field.getAttribute('type');

		// Selects
		if (field.options) {
			for (let i = 0; i < field.options.length; i++) {
				if (field.options[i].selected) {
					formData.append(field.getAttribute('name'), field.options[i].value);
				}
			}
		}
		// Checkbox and radio
		else if (type === 'checkbox' || type === 'radio') {
			if (field.checked) {
				formData.append(field.getAttribute('name'), field.value);
			}
		}
		// Files
		else if (type === 'file') {
			for (let j = 0; j < field.files.length; j++) {
				formData.append(field.getAttribute('name'), field.files[j]);
			}
			// All other fields
		} else {
			formData.append(field.getAttribute('name'), field.value);
		}

		return formData;
	}

	/**
	 * Validates a step in a multi step form.
	 * @private
	 */
	_validateStep() {
		const currentStep = this.formNode.querySelector(
			'.form__section[aria-hidden="false"]'
		);
		let currentStepFields = [];

		if (currentStep) {
			currentStepFields = currentStep.querySelectorAll(
				'input, select, fieldset'
			);
		}

		return this._checkValidity(currentStepFields);
	}

	/**
	 * Shows a step in a multi step form.
	 * @param {String} stepId - The id of the step section node.
	 * @private
	 */
	_showStep(stepId) {
		const stepButtons = this.formNode.querySelectorAll('[aria-controls]');
		const steps = this.formNode.querySelectorAll('.form__section');
		const nextStep = document.getElementById(stepId);
		const nextStepHeadline = nextStep.querySelector('.form__section__headline');
		const nextStepButton = this.formNode.querySelector(
			'[aria-controls=' + stepId + ']'
		);

		stepButtons.forEach(btn => {
			btn.setAttribute('aria-expanded', false);
		});

		steps.forEach(step => {
			step.setAttribute('aria-hidden', true);
			step.classList.add('form__section--hidden');
		});

		nextStep.setAttribute('aria-hidden', false);
		nextStep.classList.remove('form__section--hidden');
		nextStepButton.setAttribute('aria-expanded', true);

		if (nextStepHeadline) {
			nextStepHeadline.focus();
		}
	}

	/**
	 * Displays a spinner and a message in button.
	 * @param {Object} btn - A button node.
	 * @private
	 */
	_displayLoadingIndicator(btn) {
		const loadingMessage =
			btn.getAttribute('data-loading-message') || 'Laddar...';
		btn.setAttribute('data-innerhtml', btn.innerHTML);
		btn.classList.add('form__button--loading');
		btn.innerHTML =
			'<span class="form__loader" aria-hidden="true"></span> ' + loadingMessage;
	}

	/**
	 * Hides the spinner and message in buttons and restores their innerHTML.
	 * @private
	 */
	_hideLoadingIndicator() {
		const loadingButtons = this.formNode.querySelectorAll(
			'.form__button--loading'
		);

		loadingButtons.forEach(btn => {
			const oldInnerHtml = btn.getAttribute('data-innerhtml');
			btn.innerHTML = oldInnerHtml;
		});
	}

	/**
	 * Returns the next button for the current step.
	 * @private
	 */
	_getCurrentNextButton() {
		const nextButtons = this.formNode.querySelectorAll('.form__button--next');
		let currentNextButton = null;

		nextButtons.forEach(btn => {
			if (btn.offsetParent !== null) {
				currentNextButton = btn;
			}
		});

		return currentNextButton;
	}

	/**
	 * Checks if all fields with requirements (required, pattern) are valid before the form is submitted
	 * @param {Array} fields - If we're validating a multistep form, the function is provided with the fields array
	 * @private
	 */
	_checkValidity(fields) {
		const allFields =
			fields ||
			this.formNode.querySelectorAll('input, select, fieldset, textarea');
		let hasValidationErrors = false;

		this._cleanupValidationErrors();

		let displayMessageHTML = '<ul>',
			nrOfInvalidFields = 0;

		allFields.forEach(field => {
			if (this._checkIfFieldHasValidationErrors(field)) {
				hasValidationErrors = true;

				displayMessageHTML +=
					'<li><strong>' +
					this._getFieldLabelNode(field).innerHTML +
					'</strong></li>';
				nrOfInvalidFields += 1;
			}
		});

		displayMessageHTML += '</ul>';

		if (hasValidationErrors) {
			this._displayValidationErrorSummary(
				nrOfInvalidFields,
				displayMessageHTML
			);
		}

		return hasValidationErrors;
	}

	/**
	 * Checks if a field has validation errors, if so, renders a validation error message
	 * @param {Node} field - The field node to validate
	 * @private
	 */
	_checkIfFieldHasValidationErrors(field) {
		let hasValidationErrors = false;

		if (
			(field.required && field.value === '') ||
			(field.getAttribute('data-required') &&
				!this._fieldsetHasOneCheckedInput(field))
		) {
			hasValidationErrors = true;
			this._showValidationErrorMessage(
				field,
				field.getAttribute('data-validation-message') ||
					'Du måste fylla i det här fältet för att gå vidare.'
			);
		} else if (field.type === 'url' && !field.checkValidity()) {
			hasValidationErrors = true;
			this._showValidationErrorMessage(
				field,
				field.getAttribute('data-validation-message') ||
					'Tillhandahåll en giltig url.'
			);
		} else if (
			field.pattern &&
			!field.value.match(field.pattern) &&
			field.value !== ''
		) {
			hasValidationErrors = true;
			this._showValidationErrorMessage(
				field,
				field.getAttribute('data-pattern-message') ||
					'Vänligen matcha det förväntade formatet.'
			);
		}

		return hasValidationErrors;
	}

	/**
	 * Writes out a validation error message in field label tag and sets field as invalid.
	 * @param {Node} field - The input field node
	 * @param {String} message - The validation message to display
	 * @private
	 */
	_showValidationErrorMessage(field, message) {
		const fieldLabel = this._getFieldLabelNode(field);
		let validationMessage = null,
			validationMessageNode = null;

		if (field.tagName === 'SELECT' && field.getAttribute('multiple')) {
			field.parentNode
				.querySelector('input')
				.classList.add('form__field--invalid');
		} else {
			field.classList.add('form__field--invalid');
		}

		validationMessage = document.createTextNode(message);
		validationMessageNode = document.createElement('span');

		validationMessageNode.appendChild(validationMessage);
		validationMessageNode.classList.add('form__label--invalid');

		fieldLabel.appendChild(validationMessageNode);
	}

	/**
	 * Checks if any input (radio/checkbox) within a required fieldset group has been checked
	 * @param {Node} fieldset - The fieldset node
	 * @private
	 */
	_fieldsetHasOneCheckedInput(fieldset) {
		const allInputs = fieldset.querySelectorAll('input');
		let hasOneValidInput = false;

		allInputs.forEach(field => {
			if (field.checked) {
				hasOneValidInput = true;
			}
		});

		return hasOneValidInput;
	}

	/**
	 * Initiates events.
	 * @private
	 */
	_initEvents() {
		const multistepButtons = this.formNode.querySelectorAll('[aria-controls]');
		const allFields = this.formNode.querySelectorAll(
			'input, fieldset, select, textarea'
		);

		// Field validation on blur
		allFields.forEach(field => {
			field.addEventListener('focusout', () => {
				const fieldIsPartOfAFieldset =
					field.parentNode.parentNode.tagName === 'FIELDSET';

				if (fieldIsPartOfAFieldset) {
					return;
				} else if (field.classList.contains('choices__input')) {
					field = field.parentNode.querySelector('select');
				}

				this._cleanupFieldValidationError(field);
				this._checkIfFieldHasValidationErrors(field);
			});
		});

		this.formNode.addEventListener('submit', event => {
			if (this._checkValidity()) {
				event.preventDefault();
				return;
			}

			// If the user submits with Enter press we need to handle multistep forms.
			const currentNextButton = this._getCurrentNextButton();
			if (currentNextButton) {
				this._submitStep(currentNextButton.getAttribute('aria-controls'));
				event.preventDefault();
				return false;
			}

			if (typeof FormData !== 'undefined') {
				if (event.preventDefault) {
					event.preventDefault();
				} else {
					event.returnValue = false;
				}

				const submitBtn = this.formNode.querySelector('[type="submit"]');
				if (submitBtn) {
					this._displayLoadingIndicator(submitBtn);
				}
				this._submit();
			}
		});

		// Multistep forms
		multistepButtons.forEach(btn => {
			btn.addEventListener('click', () => {
				if (btn.getAttribute('data-epiform-previous')) {
					const currentStepIndexField = this.formNode.querySelector(
						'[name="__FormCurrentStepIndex"]'
					);
					if (currentStepIndexField) {
						currentStepIndexField.value =
							parseInt(currentStepIndexField.value, 10) - 1;
					}
					this._showStep(btn.getAttribute('aria-controls'));
					return false;
				}
				if (typeof FormData !== 'undefined') {
					this._cleanupOldStatusMessages();
					if (
						btn.classList.contains('form__button--prev') ||
						!this._validateStep()
					) {
						this._displayLoadingIndicator(btn);
						this._submitStep(btn.getAttribute('aria-controls'));
					}
				} else {
					this.formNode.submit();
				}
			});
		});
	}
}

import ToggleLink from './components/toggleLink/toggleLink';
import Megadrop from './components/megadrop/megadrop';
import MobileMenu from './components/mobileMenu/mobileMenu';
import TabUsability from './components/tabUsability/tabUsability';
import EpiserverForm from './components/episerverForm/episerverForm';
import { wrap } from './components/utilities/utilities';
import { setCookie, getCookie } from './components/cookie/cookie';

/**
 * App
 * This starting point for this application.
 * @version 3.0.0
 * @exports App
 */
export default class App {
	constructor() {
		// Init menu
		new Megadrop();

		// Init mobile menu
		new MobileMenu(document.querySelector('[data-type="mobileMenu"]'), {
			ajaxPath: mainConfig.menuAjaxPath,
			ajaxActivePane: mainConfig.menuAjaxActivePane,
			searchField: true,
			searchMethod: mainConfig.searchMethod,
			searchPage: mainConfig.searchPage,
			searchParameter: mainConfig.searchParameter,
			searchPlaceholder: mainConfig.searchPlaceholder,
		});

		// Init toggle link
		new ToggleLink();

		// Init tabUsability
		new TabUsability();

		// Init table wrap for mobile scrolling of tables in rte.
		this._initRteTableWrap();

		// Init ajaxForm
		this._initAjaxForm();

		// Init episerverForms
		this._initEpiserverForm();

		// Init Share
		this._initShare();

		// Init AutoSubmit
		this._initAutoSubmit();

		// Init Fancybox
		this._initFancybox();

		// Init cookies
		this._initCookies();

		// Init checked checkboxes
		this._initCheckedCheckboxes();

		// Init pagination is active
		this._initPaginationIsActive();

		// Init image preview
		this._initImagePreview();
	}

	/**
	 * Function that initiates the ajax-form functionality.
	 * @private
	 */
	_initAjaxForm() {
		let els = document.querySelectorAll('[data-type="ajaxForm"]');

		if (els.length > 0) {
			import(
				/* webpackChunkName: "ajaxForm" */ './components/ajaxForm/ajaxForm'
			).then(AjaxForm => {
				for (let i = 0; i < els.length; i++) {
					let el = els[i];

					if (el.getAttribute('data-ajaxform-initiated') === null) {
						new AjaxForm.default(el);
						el.setAttribute('data-ajaxform-initiated', true);
					}
				}
			});
		}
	}

	/**
	 * Function that initiates the episerverform functionality.
	 * @private
	 */
	_initEpiserverForm() {
		let els = document.querySelectorAll('[data-type="episerverForm"]');

		if (els.length > 0) {
			for (let i = 0; i < els.length; i++) {
				let el = els[i];

				if (el.getAttribute('data-episerverform-initiated') === null) {
					new EpiserverForm(el);
					el.setAttribute('data-episerverform-initiated', true);
				}
			}
		}
	}

	/**
	 * Function that wraps all tables in rte with a div to enable the scroll-feature in mobile.
	 * @private
	 */
	_initRteTableWrap() {
		let els = document.querySelectorAll('.rte table');

		els.forEach(el => {
			let wrapper = document.createElement('div');
			wrapper.classList.add('table-scrollable');
			wrap(el, wrapper);
		});
	}

	/**
	 * Function that initiates the share functionality.
	 * @private
	 */
	_initShare() {
		if (document.querySelectorAll('[data-type="share"]').length > 0) {
			import(/* webpackChunkName: "share" */ './components/share/share').then(
				Share => {
					new Share.default();
				}
			);
		}
	}

	/**
	 * Function that initiates the autosubmit functionality.
	 * @private
	 */
	_initAutoSubmit() {
		if (document.querySelectorAll('[data-type="autosubmit"]').length > 0) {
			import(
				/* webpackChunkName: "autoSubmit" */ './components/autoSubmit/autoSubmit'
			).then(AutoSubmit => {
				new AutoSubmit.default();
			});
		}
	}

	_initFancybox() {
		/* global $ */
		import(/* webpackChunkName: "jquery" */ 'jquery').then(jquery => {
			window.$ = window.jQuery = jquery.default;
			import(/* webpackChunkName: "fancybox" */ '@fancyapps/fancybox').then(
				() => {
					$('.fancybox').fancybox({});
				}
			);
		});
	}

	/**
	 * Initates cookies and cookiebar
	 * @private
	 */
	_initCookies() {
		const cookiebar = document.querySelector('.cookiebar');
		if (!getCookie('acceptCookieLBA')) {
			cookiebar.classList.remove('cookiebar--hidden');

			cookiebar
				.querySelector('.cookiebar__accept')
				.addEventListener('click', e => {
					e.preventDefault();
					setCookie('acceptCookieLBA', true, 10950, '/');
					cookiebar.classList.add('cookiebar--hidden');
				});
		}
	}

	/**
	 * Initates checked checkboxes
	 * @private
	 */
	_initCheckedCheckboxes() {
		import(/* webpackChunkName: "jquery" */ 'jquery').then(jquery => {
			window.$ = window.jQuery = jquery.default;

			$('#field-input-private').change(function() {
				if ($(this).is(':checked')) {
					$('.form__fields-holder--contact').addClass('is-active');
					$('.form__fields-holder--extra').addClass('is-active');
					$('.form__fields-holder--invoice').addClass('is-active');
					$('.form__fields-holder--company').removeClass('is-active');
					$('.form__fields-holder--association').removeClass('is-active');
				}
			});

			$('#field-input-company').change(function() {
				if ($(this).is(':checked')) {
					$('.form__fields-holder--contact').addClass('is-active');
					$('.form__fields-holder--extra').addClass('is-active');
					$('.form__fields-holder--invoice').addClass('is-active');
					$('.form__fields-holder--company').addClass('is-active');
					$('.form__fields-holder--association').removeClass('is-active');
				}
			});

			$('#field-input-association').change(function() {
				if ($(this).is(':checked')) {
					$('.form__fields-holder--contact').addClass('is-active');
					$('.form__fields-holder--extra').addClass('is-active');
					$('.form__fields-holder--invoice').addClass('is-active');
					$('.form__fields-holder--association').addClass('is-active');
					$('.form__fields-holder--company').removeClass('is-active');
				}
			});
		});
	}

	/**
	 * Initates checked checkboxes
	 * @private
	 */
	_initPaginationIsActive() {
		import(/* webpackChunkName: "jquery" */ 'jquery').then(jquery => {
			window.$ = window.jQuery = jquery.default;
			$('.pagination ul li').click(function() {
				$('.pagination ul li').removeClass('is-active');
				$(this).addClass('is-active');
			});
		});
	}

	/**
	 * Initates image preview
	 * @private
	 */
	_initImagePreview() {
		import(/* webpackChunkName: "jquery" */ 'jquery').then(jquery => {
			window.$ = window.jQuery = jquery.default;
			$('.banner-thumbnail').hover(function() {
				var getImgSrc = $(this).attr('src');
				var getImgSrcset = $(this).attr('srcset');
				$(this)
					.addClass('active')
					.parent()
					.siblings()
					.children()
					.removeClass('active');
				$('.block-banner')
					.children()
					.attr('srcset', getImgSrcset);
				$('.block-banner')
					.children()
					.attr('src', getImgSrc);
			});
		});
	}
}
